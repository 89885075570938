import React from "react"
import PropTypes from "prop-types"

export default class BlockRemove extends React.Component {

  render () {
    const className = this.props.class + " block-remove";
    return (
      <span className={className} onClick={this.props.clickHandler}>x</span>
    );
  }
}

BlockRemove.propTypes = {
  class: PropTypes.string,
  clickHandler: PropTypes.func,
}

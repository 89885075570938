import React from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { createRef } from "react"

import BlockRemove from "./BlockRemove"

export default class CodeBlock extends React.Component {

  blockRef = createRef(null);

  constructor (props) {
      super(props);
      this.state = {
        published: this.props.published || false,
        value: ""
      }

      this.TAB = 9;

      if (typeof this.props.code == "string" && this.props.code.length > 0) {

        this.state.value = this.props.code;
      }
  }

  indent (event) {

    const key = event.keyCode;

    if(key == this.TAB) {

        const value = event.target.value;
        const start = value.substring(0, event.target.selectionStart);
        const end = value.substring(event.target.selectionEnd);

        this.setState({value: start + "    " + end});
        event.target.selectionStart = start + 4;
        event.preventDefault();
    }
  }

  change (event) {
    this.setState({value: event.target.value});
  }

  pageLoadCallback (event) {

    // Highlights current code block
    hljs.highlightBlock(this.blockRef.current);
  }

  /**
   * Highlights the code after the component has been mounted
   */
  componentDidMount () {

    window.addEventListener('load', this.pageLoadCallback.bind(this));
    window.addEventListener('turbolinks:load', this.pageLoadCallback.bind(this));
  }

  render () {

    const form = (
      <p className="code-block-form">
        <BlockRemove class="code-block-remove" clickHandler={this.props.removeCallback}/>
        <label>Source Code</label>
        <textarea rows="15"
                  cols="80"
                  onKeyDown={this.indent.bind(this)}
                  onChange={this.change.bind(this)}
                  value={this.state.value}
                  name={"article[blocks][" + this.props.order + "][code]"}>
        </textarea>
      </p>
    );

    const source = (
      <pre>
        <code ref={this.blockRef} className="hljs">
          {this.state.value}
        </code>
      </pre>
    );

    if(this.state.published) {
        return source;
    } else {
        return form;
    }
  }
}

CodeBlock.propTypes = {
  code: PropTypes.string,
};

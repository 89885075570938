import React from "react"
import PropTypes from "prop-types"

import BlockRemove from "./BlockRemove"

export default class CircuitBlock extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      published: this.props.published || false,
      circuit: "",
      label: "",
    }

    if(typeof this.props.circuit == "string" && this.props.circuit.length > 0) {
      this.state.circuit = this.props.circuit;
    }

  }

  componentDidMount () {

    var circuit_json = {"width": 600, "height": 200};
    var element = $("#circuit-simcir_" + this.props.order);

    if (this.state.circuit) {
        circuit_json = JSON.parse(this.state.circuit);
    }

    /* Initialize circuits js library */
    simcir.setupSimcir(element, circuit_json);

    this.setState({
      element: element,
      circuit: JSON.stringify(circuit_json)
    })
  }

  storeCircuit(event) {
    var data = simcir.controller(
        this.state.element.find('.simcir-workspace')
    ).text();

    this.setState({circuit: data});

    event.stopPropagation();
    event.preventDefault();
  }

  onInputChange (event) {

    this.setState({
        circuit: event.target.value
    })
  }

  updateCircuit (event) {
    /* Update circuit on SimCir */
    simcir.setupSimcir(this.state.element, JSON.parse(this.state.circuit));
  }

  render () {
    const circuit = (
        <section className="circuit-block-show" >
            <div id={"circuit-simcir_" + this.props.order} >
            </div>
        </section>
    )

    const form = (
        <p className="circuit-block-form">
          <section>
            <label>Circuit</label>
            <BlockRemove class="circuit-block-remove" clickHandler={this.props.removeCallback}/>
            <div id={"circuit-simcir_" + this.props.order} >
            </div>
            <input
                type="text"
                name={"article[blocks][" + this.props.order + "][circuit]"}
                value={this.state.circuit}
                onChange={this.onInputChange.bind(this)}
                onBlur={this.updateCircuit.bind(this)} />
            <button type="save-circuit-btn" onClick={this.storeCircuit.bind(this)}>Save</button>
          </section>
        </p>
    )

    if (this.state.published) {
        return circuit;
    } else {
        return form;
    }
  }
}

CircuitBlock.propTypes = {
  published: PropTypes.bool,
  circuit: PropTypes.string,
}

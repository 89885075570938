import React from "react"
import PropTypes from "prop-types"

import BlockRemove from "./BlockRemove"

export default class ContentBlockList extends React.Component {

  render () {

    return (
      <div className="ContentBlockList">
        {this.props.blocks.map(function (component) {
          return component
        })}
      </div>
    );
  }
};

ContentBlockList.propTypes = {
  blocks: PropTypes.array,
}

import React from "react"
import PropTypes from "prop-types"

import BlockRemove from "./BlockRemove"

export default class MathBlock extends React.Component {

  constructor (props) {
      super(props);
      this.state = {
        published: this.props.published || false,
        value: "\\begin{flalign}\n & \\\\ \n\\end{flalign}"
      }

      if (typeof this.props.math == "string" && this.props.math.length > 0) {

        this.state.value = this.props.math;
      }
  }

  genTex () {

    var output = document.getElementById("math-block-" + this.props.order);
    output.innerHTML = '';

    MathJax.texReset();
    var options = MathJax.getMetricsFor(output);

    MathJax.tex2chtmlPromise(this.state.value, options).then(function (node) {

        output.appendChild(node);
        MathJax.startup.document.clear();
        MathJax.startup.document.updateDocument();

      }).catch(function (err) {

        let preElm = document.createElement('pre');
        let txtElm = document.createTextNode(err.message);
        output.appendChild(preElm).appendChild(txtElm);
      })
  }

  change (event) {
    this.setState({value: event.target.value});
  }

  click (event) {

    this.genTex();

    event.preventDefault();
    event.stopPropagation();
  }

  blur (event) {

    this.genTex();
    event.stopPropagation();
  }

  pageLoadCallback (event) {

      this.genTex();
  }

  componentDidMount () {

    window.addEventListener('load', this.pageLoadCallback.bind(this));
    window.addEventListener('turbolinks:load', this.pageLoadCallback.bind(this));
  }

  render () {
    const form = (
      <p className="math-block-form">
        <BlockRemove class="math-block-remove" clickHandler={this.props.removeCallback}/>
        <label>Math</label>
        <textarea rows="6"
                  cols="80"
                  onChange={this.change.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.value}
                  name={"article[blocks][" + this.props.order + "][math]"}>
        </textarea>
        <button onClick={this.click.bind(this)} >Generate</button>

        <div id={"math-block-" + this.props.order} className="math-content" name={"article[blocks][" + this.props.order + "][math]"}>
        </div>
      </p>
    );

    const mathContent = (
      <div id={"math-block-" + this.props.order} className="math-content" name={"article[blocks][" + this.props.order + "][math]"}>
      </div>
    );

    if(this.state.published) {
        return mathContent;
    } else {
        return form;
    }
  }
}

MathBlock.propTypes = {
  math: PropTypes.string,
}

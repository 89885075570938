import React from "react"
import PropTypes from "prop-types"

import BlockRemove from "./BlockRemove"

export default class HelloWorld extends React.Component {

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <React.Fragment>
        Greeting: {this.props.greeting}
      </React.Fragment>
    )
  }
}

HelloWorld.propTypes = {
    greeting: PropTypes.string
};
